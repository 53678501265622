import { createActions, using } from 'redux-box'

export default createActions({
  requestParents: using(),
  requestUpdateFreadosByChildId: using("data"),
  requestUpdateStreakByChildId: using("data"),
  requestParentsByOffset: using("offset"),
  requestUpdateParentProfile: using("parent"),
  requestParentById: using("parentId"),
  requestChildren: using(),
  requestChildrenByOffset: using("offset"),
  requestChildrenByParentId: using("parentId"),
  requestUpdateChildProfile: using("child"),
  requestUpdateChildSubLevel: using("child"),
  requestChildById: using("childId"),
  setParentSearch: using("search"),
  setChildSearch: using("search"),
  setActiveParent: using("parent"),
  setActiveChild: using("child"),
  setChildrenList: using("children"),
  unsetChildrenList: using(),
  exportParentData: using("data"),
  exportChildData: using("data"),
  setFilterParentSearch: using("data"),
  setFilterChildSearch: using("data"),
  validateLinkSchool: using("code"),
  getSections: using("data"),
  linkSchoolCode: using("data"),
  unlinkSchoolCode: using("data"),
});