import React, { Component } from "react";
import {
  ActivityMainFormContainer,
  CreateActivityContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { module as ui } from "@st/ui";
import { module as inviteCode } from "@st/inviteCode";
import { module as activity } from "@st/activity";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import Select from "react-select";
import { EnterClassInputContainer } from "./ClassCreatorForm.styled";
import {
  UploadContainer,
  SubHeading,
  TagChipsContainer,
  TagChips,
  ThemeButton,
  FlexBetween,
} from "static/styled/custom.styled";
import { SelectContainer } from "../../invite-code-view/InviteCodeView.styled";

class ClassCreatorForm extends Component {
  constructor(props) {
    super();
    this.state = {
      class_info: {
        grade: null,
        selectedGrade: null,
        name_list: [],
      },
      temp_name_list: [],
      class_name: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.inviteCode.classResultList.length > 0) {
      this.setState({
        class_info: {
          ...this.state.class_info,
          name_list: nextProps.inviteCode.classResultList,
        },
      });
    } else if (nextProps.inviteCode.classResultList.length == 0) {
      this.setState({
        class_info: {
          ...this.state.class_info,
          name_list: [],
        },
      });
    }
  }

  componentDidMount() {}

  handleGradeChange = (selectedGrade) => {
    let { schoolEditInfo } = this.props.inviteCode;
    this.setState({
      temp_name_list: [],
      class_info: {
        ...this.state.class_info,
        selectedGrade: selectedGrade,
        grade: selectedGrade.id,
      },
    });
    let classData = {
      school: schoolEditInfo.id,
      grade: selectedGrade.id,
    };
    this.props.inviteCode.getClass(classData);
  };

  handleChangeChange = (evt) => {
    this.setState({ class_name: evt.target.value });
  };

  classAvailable = (name) => {
    let name_list = this.state.class_info.name_list;
    if (name_list.length > 0) {
      name_list = name_list.filter(
        (className) => className.name.toLowerCase() === name.toLowerCase()
      );
      //    console.log(name_list)
      if (name_list.length > 0) return true;
      return false;
    } else {
      return false;
    }
  };

  handleAddClick = () => {
    if (this.state.class_name) {
      let temp = this.state.temp_name_list;
      let name = {
        name: this.state.class_name,
      };
     if (!this.classAvailable(this.state.class_name)) temp.push(name);
     else {
       swal("Oops!", "Class already available.", "error");
     }
      this.setState({
        class_name: null,
        temp_name_list: temp,
        class_info: {
          ...this.state.class_info,
          name_list: [...temp, ...this.props.inviteCode.classResultList],
        },
      });
    }
  };

  handleContinueBtn = () => {
    if (this.state.class_info.grade && this.state.temp_name_list.length > 0) {
      let { class_info, temp_name_list } = this.state;
      let { schoolEditInfo } = this.props.inviteCode;
      for (const key in temp_name_list) {
        const element = temp_name_list[key];
        let classData = {
          name: element.name,
          school: schoolEditInfo.id,
          grade: class_info.grade,
        };
        this.props.inviteCode.createClass(classData);
      }
      // this.props.ui.hideModal();
    } else {
      swal(
        "Oops!",
        "You must select grade and class name to create class",
        "error"
      );
    }
  };
  render() {
    let { class_info } = this.state;
    return (
      <div>
        <CreateActivityContainer>
          <ActivityMainFormContainer>
            {/* <form> */}
            <h4>Add Class</h4>
            <SelectContainer>Freadom Grade</SelectContainer>
            <Select
              className="dropdown-container"
              value={class_info.selectedGrade}
              onChange={this.handleGradeChange}
              options={this.props.activity.gradeList}
            />
            <EnterClassInputContainer>
              <input
                type="text"
                placeholder="Enter class name"
                value={this.state.class_name}
                onChange={this.handleChangeChange}
                // onKeyPress={this.handleEnterClick}
              />
              <button onClick={this.handleAddClick}> +</button>
            </EnterClassInputContainer>
            <br></br>
            <div style={{ minHeight: 100 }}>
              {this.state.class_info.name_list &&
                this.state.class_info.name_list.length > 0 && (
                  <TagChipsContainer>
                    {this.state.class_info.name_list.map((data, id) => (
                      <TagChips key={data.id}>
                        {data.name}
                        {/* <img
                                        className="image-fluid"
                                        src={require("static/img/tagCloseIcon.png")}
                                        alt="Edit icon"
                                    /> */}
                      </TagChips>
                    ))}
                  </TagChipsContainer>
                )}
            </div>
            {/* </form> */}
            <ThemeButton
              primary
              type="button"
              disabled={this.state.temp_name_list.length > 0 ? false : true}
              onClick={this.handleContinueBtn}
            >
              {" "}
              Add Class{" "}
            </ThemeButton>
            <div style={{ minHeight: 80 }}></div>
          </ActivityMainFormContainer>
        </CreateActivityContainer>
      </div>
    );
  }
}

export default connectStore({
  ui,
  inviteCode,
  activity,
})(ClassCreatorForm);
