import React, { Component } from "react";
import {
  UserDetailsContainer,
  UserDetailValueLabels,
  UserField2,
  UserField3,
  UserFieldEmail,
  UserFieldName,
  UserListLoadingContainer,
  Pagination,
  PaginationButton,
} from "@c/users-view/MainContent.styled";

import {
  AutoContainer,
  ListContainer,
  EmptyContainer,
} from "@sc/custom.styled";
import { Link } from "react-router-dom";
import ParentCard from "./parent-card/ParentCard";
import { module as user } from "@st/user";
import { connectStore } from "redux-box";

// export default connectStore({
//   user,
// })


class ParentsList extends Component {
  constructor(props) {
    super();
  }
  componentWillMount() {
    this.props.user.requestParents();
  }

  componentWillUnmount() {
    this.props.user.setActiveParent(null);
  }
  handleNext = () => {
    let { user } = this.props;
    if (user.parent_list.has_next) {
      user.requestParentsByOffset(user.parent_list.next_page);
    }
  };
  handlePrevious = () => {
    let { user } = this.props;
    if (user.parent_list.prev_page >= 0 && user.parent_list.offset !== 0) {
      user.requestParentsByOffset(user.parent_list.prev_page);
    }
  };

  render() {
    let { user } = this.props;
    console.log("user", user);

    let parentsList =
      user && user.parent_list.result && user.parent_list.result.length > 0 ? (
        user.parent_list.result.map((data, index) => (
          <Link to={"/users/parents/" + data.id} key={index}>
            <ParentCard parentDetail={data} />
          </Link>
        ))
      ) : (
        <EmptyContainer>
          <h4>NO PARENTS RECORDS ADDED</h4>
        </EmptyContainer>
      );
    return (
      <UserDetailsContainer>
        <AutoContainer style={{ padding: "0 0 50px 0" }}>
          <UserDetailValueLabels>
            <UserFieldName primary>Name</UserFieldName>
            <UserFieldEmail primary>Email</UserFieldEmail>
            <UserField2 primary>Phone no.</UserField2>
            <UserField2 primary> Kids</UserField2>
            <UserField2 primary>Phone Verified </UserField2>
            <UserFieldEmail primary align width="240px">
              Status
            </UserFieldEmail>
            <UserField2 primary>Start date</UserField2>
            <UserField3 />
          </UserDetailValueLabels>

          {this.props.user && !this.props.user.fetch_parent ? (
            <ListContainer>{parentsList}</ListContainer>
          ) : (
            <UserListLoadingContainer>
              <img
                alt="loading"
                width="40"
                height="40"
                src={require("static/img/Theme-loader.gif")}
              />
            </UserListLoadingContainer>
          )}
        </AutoContainer>

        <Pagination>
          <PaginationButton
            color={this.props.user.parents_offset !== 0 ? "#ff8a23" : "#d5d5d5"}
            onClick={this.handlePrevious}
          >
            <i className="fa fa-chevron-left" />
          </PaginationButton>
          <PaginationButton
            color={
              this.props.user.parent_list &&
              this.props.user.parent_list.has_next
                ? "#ff8a23"
                : "#d5d5d5"
            }
            onClick={this.handleNext}
          >
            <i className="fa fa-chevron-right" />
          </PaginationButton>
        </Pagination>
      </UserDetailsContainer>
    );
  }
}
// export default ParentsList;
export default connectStore({
  user
 })(ParentsList)