import React, {Component} from 'react';
import {UserDetailValues, UserField2, UserField3, UserFieldEmail, UserFieldName, ToggleButton, ToggleHandler, ToggleStatus} from "@c/users-view/MainContent.styled";
import {module as user} from '@st/user';
import { connectStore } from "redux-box";
// export default connectStore ({
//     user
// })


class ParentCard extends Component {
    handleToggle = (e) => {
        let {user}=this.props;
        if(this.props.parentDetail)
        user.requestUpdateParentProfile({...this.props.parentDetail,is_active:!this.props.parentDetail.is_active})
        e.preventDefault();
    };

    constructor(props) {
        super();
        this.state = {
            showToggle: false,
            startDate:'',
        }
    }

    componentWillMount() {
        this.setState({
            showToggle: !this.props.parentDetail.is_active
        });
        if(this.props.parentDetail) {
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            let date = new Date(this.props.parentDetail.created_at);
            let startDate = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
            this.setState({
                startDate:startDate
            })
        }
        }

    render() {
        //let state = this.state;
        let user = this.props.parentDetail;
        // let parent_info = null;
        return (
            <div style={{padding: '0px 5px'}}>
            <UserDetailValues>
                <UserFieldName>{user.name} </UserFieldName>
                <UserFieldEmail> {user.email} </UserFieldEmail>
                <UserField2 > {user.contact_no} </UserField2>
                <UserField2> {user.child_count}</UserField2>
                {user.is_contact_no_verified ? <UserField2 color="#20bf6b">YES </UserField2>:<UserField2 color="#fc5c65" >NO </UserField2>}
                <UserFieldEmail primary align width="240px">
                    {user.is_active ?
                        <ToggleStatus primary fontSize="12px" onClick={this.handleToggle}> Disable </ToggleStatus> :
                        <ToggleStatus fontSize="12px" onClick={this.handleToggle}> Disabled</ToggleStatus>}
                    <ToggleButton style={{margin: '0px 6px'}}
                                  color={user.is_active?'#ff8f26':'#d5d5d5'}
                                  height="22px">
                        {!user.is_active ?
                            <ToggleHandler width="18px" height="18px" primary onClick={this.handleToggle}/> :
                            <ToggleHandler width="18px" height="18px" left="25px" onClick={this.handleToggle}/>}
                    </ToggleButton>
                    {user.is_active ?
                        <ToggleStatus fontSize="12px" onClick={this.handleToggle}> Enabled </ToggleStatus> :
                        <ToggleStatus fontSize="12px" primary onClick={this.handleToggle}> Enable</ToggleStatus>}
                </UserFieldEmail>
                <UserField2> {this.state.startDate}</UserField2>
                <UserField3>
                    <img src={require("static/img/selection_icon.png")} className="select-icon" alt="Select Icon"/>
                </UserField3>
            </UserDetailValues>
            </div>
        )
    }
}

// export default ParentCard;
export default connectStore({
    user
   })(ParentCard)